import {Injectable} from '@angular/core';
import { EventKey } from './event-key';
import { Util } from './util';
import { Events } from './Events';


@Injectable()
export class GlobalVars {
  
  private baseURL:string;
  private pagseguroUrl:string;
  private appVersionNumber:number;
  private buildNumber:number;
  private buyerObj:any;
  private pageLoadedControl:Map<string,boolean> = new Map<string,boolean>();
  private authText:string;
  
  //TODO: Esse atributo (clientObj) e utilizado em todo o codigo e por questao carregamento assincrono algumas pages apresentaram problema de tentar usar antes do atributo esta preenchido.
  private clientObj;
  private visibleSplitPane:boolean;
  private activeRootPage:string;
  private pagSeguroSessionId:string;
  private pwa:boolean;
  private actualLocationAddress;
  private settingsObj;
  private loggedInVendeMais:boolean = false;
  private listWayPayment = [];
  private cardWayPayment;
  private recaptchaInfo:string;
  private clientChildren:any = [];

  private pIdURLParameter:string; //Id do Produto

  static TABS_ROOT_PAGE = "TabsPage";
  static HOME_ROOT_PAGE = "HomePage";
  static UPDATE_VERSION_ROOT_PAGE = "UpdateVersionInfoPage";
  static VENDEMAIS_TABS_PAGE = "VendemaisTabsPage";
  static CLIENT_LIST_PAGE = "ClientListPage";

  //AppConfig
  constructor(public events: Events) {
    
    //this.baseURL = 'http://localhost:8080/AllDelivery';
    
    this.authText = 'docepedacoapp:aWd%$,Msd5tY@!';
    this.baseURL = 'https://ymbu.com.br/vendemais';
    this.pagseguroUrl = 'https://stc.pagseguro.uol.com.br';
    this.appVersionNumber = 200;
    this.buildNumber = 2;
    //this.facebookPixelId = '853559189089689';
  }

  setClientChildren(clientChildren:[]){
    this.clientChildren = clientChildren;
  }

  getClientChildren(){
    return this.clientChildren;
  }

  getAuthText():string{
    return this.authText;
  }

  setAuthText(authText:string){
    this.authText = authText;
  }

  getListWayPayment(){
    return this.listWayPayment;
  }

  setListWayPayment(listWayPayment){
    this.listWayPayment = listWayPayment; 
  }

  getCardWayPayment(){
    return this.cardWayPayment;
  }

  setCardWayPayment(cardWayPayment){
    this.cardWayPayment = cardWayPayment;
  }

  setLoggedInVendeMais(logged:boolean){
    this.loggedInVendeMais = logged;
  }

  isLoggedInVendeMais():boolean{
    return this.loggedInVendeMais;
  }

  getVendeMaisTabsPageValue(){
    return GlobalVars.VENDEMAIS_TABS_PAGE;
  }

  setPageLoaded(pageName:string, loaded:boolean){
    this.pageLoadedControl.set(pageName,loaded);
  }

  isPageLoaded(pageName:string):boolean{
    return this.pageLoadedControl.get(pageName);
  }

  getPageLoadedControlMap():Map<string,boolean>{
    return this.pageLoadedControl;
  }

  setSettingsObj(settingsObj:any){
    this.settingsObj = settingsObj;
    this.events.publish(EventKey.SETTINGS_CHANGED);
  }

  getSettingsObj():any{
    return this.settingsObj;
  }

  setIsPwa(value:boolean){
    this.pwa = value;
  }
  
  setPagSeguroSessionId(pagSeguroSessionId:string){
    this.pagSeguroSessionId = pagSeguroSessionId;
  }

  getPagSeguroSessionId(){
    return this.pagSeguroSessionId;
  }

  setActiveRootPage(activeRootPage:any){
    this.activeRootPage = activeRootPage;
  }

  getActiveRootPage():string{
    return this.activeRootPage;
  }

  isTabsPageRootActive():boolean{
    return this.getActiveRootPage() == GlobalVars.TABS_ROOT_PAGE;
  }

  isHomePageRootActive():boolean{
    return this.getActiveRootPage() == GlobalVars.HOME_ROOT_PAGE;
  }

  isUpdateVersionPageRootActive():boolean{
    return this.getActiveRootPage() == GlobalVars.UPDATE_VERSION_ROOT_PAGE;
  }

  isVendeMaisTabsPageRootActive():boolean{
    return this.getActiveRootPage() == GlobalVars.VENDEMAIS_TABS_PAGE;
  }

  setClientObj(obj:any){
    this.clientObj = obj;
    this.events.publish(EventKey.CHANGE_GLOBAL_CLIENT_OBJ);
  }

  getClientObj():any{
    return this.clientObj;
  }

  setBuyerObj(obj:any){
    this.buyerObj = obj;
    this.events.publish(EventKey.CHANGE_GLOBAL_BUYER_OBJ);
  }

  getBuyerObj():any{
    return this.buyerObj;
  }

  setBaseURL(value:string) {
    this.baseURL = value;
  }

  getBaseURL():string{
      return this.baseURL;
  }

  setPagseguroUrl(value:string){
    this.pagseguroUrl = value;
  }

  getPagseguroUrl():string{
    return this.pagseguroUrl;
  }

  setAppVersionNumber(version:number){
    this.appVersionNumber = version;
  }

  getAppVersionNumber():number{
    return this.appVersionNumber;
  }

  setIsVisibleSplitPane(visible:boolean){
    this.visibleSplitPane = visible;
  }

  setActualLocationAddress(actualLocationAddress){
    this.actualLocationAddress = actualLocationAddress;
  }

  getActualLocationAddress(){
   return this.actualLocationAddress;
  }

  //TODO: Alterar o nome desse metodo para alguma coisa tipo isDesktopMode para fazer mais sentido em todo projeto.
  isVisibleSplitPane():boolean{
    return this.visibleSplitPane;
  }

  //TODO: Criar outra forma de validar se o app esta rodando no browser (Ex:Platform)
  isPwa():boolean{
    return this.pwa;
  }

  useRecaptcha():boolean{
    if(this.settingsObj && this.settingsObj.useRecaptcha){
      if(this.isPwa()){
        return this.settingsObj.showRecaptchaPwa;
      } else {
        return this.settingsObj.showRecaptchaApp;
      }
    }else{
      return false;
    }
  }

  getXClient():string{
    return (this.isPwa())?Util.CLIENT_TYPE_PWA:Util.CLIENT_TYPE_APP;
  }

  setRecaptchaInfo(recaptchaInfo:string){
    this.recaptchaInfo = recaptchaInfo;
  }

  getRecaptchaInfo():string{
    return this.recaptchaInfo;
  }

  setPIdURLParameter(parameter){
    this.pIdURLParameter = parameter;
  }

  getPIdURLParameter(){
    return this.pIdURLParameter;
  }
  
}